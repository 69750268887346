import { db } from '../app/indexeddb'; 

function delay(n:number){
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
}
export async function fetchFileWithCache (  url:string ){

     let savedata = await (db as any ).fileCache.get({url: url });
  
      if(!savedata){ 
            try{
                const resp  = await fetch( url, {"cache":"force-cache"});

                if(200 == resp.status){
      		
      		          const imageBlob = await resp.blob();
      		          const reader = new FileReader();
      		          const save  = await new Promise((resolve) => {
                          reader.readAsDataURL(imageBlob);
                          reader.onloadend = async () => {
                
                            const base64data = reader.result;
                            savedata = { url:url  , base64:  base64data };
                            await (db as any ).fileCache.put( savedata  );
      		                  resolve(true);
      		              }
      		          });
      		      }
      		}catch(e){
      		    
      		    console.log(e);
      		}
      }
     return savedata;  		
}
